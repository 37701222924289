import './PopUpInstaller.css'
import { useState} from 'react'

const PopUpInstaller = (props:any) => { 
    let  text = 'To install this web app om your phone: open from Safari the shere menu and then select "Add to Homescreen"'

    const [closeBtn, setCloseBtn]= useState(true);

    let locStor = localStorage.getItem('popup')
    if(locStor === null){
        localStorage.setItem('popup', '1')
    }

    
    let style = ''
    if(closeBtn === true) { 
        style = 'popUp-installer-container'
        
      }
    else {
        style = 'popUp-installer-container close'
      }   

    const xBtnHandler = () => {
        style = 'popUp-installer-container close'
        setCloseBtn(false)
        localStorage.removeItem('popup')
        localStorage.setItem('popup', '0')
    }

    let checkPopUpStyle = localStorage.getItem('popup')
    let popupDisplayStyle=''
    if(checkPopUpStyle  === '1'){
         popupDisplayStyle = 'popup-wrapper'
    }
    else{
        popupDisplayStyle = 'close'
    }
   

    return <div className={popupDisplayStyle}>
        <div className={style}> 
            <div className='xBtn' onClick={xBtnHandler}></div>
            <div>{text}</div>
        </div>
    </div>
    

}

export default PopUpInstaller