import { useState} from 'react';
import {Unity, useUnityContext} from "react-unity-webgl";
import DownloadingPage from './DownloadingPage';
import WebGLSupport from './WebGLSupport';
import ButtonPWA from './ButtonPWA';
import PopUp from './PopUp';

import styles from "./App.module.css";
import './FullScreen.css'




export interface Props{
    browser:string
}

function App(props:Props) {
    const { unityProvider } = useUnityContext({
        loaderUrl: "/mearplayer/web_release.loader.js",
        dataUrl: "/mearplayer/web_release.data.unityweb",
        frameworkUrl: "/mearplayer/web_release.framework.js.unityweb",
        codeUrl: "/mearplayer/web_release.wasm.unityweb",
    });   
      
    const[styleFS, setStyleFS]=useState('fullScreenOpen')
    const[fullScreenState, setsetfullScreenState]=useState(true)
    let el = document.body;
    let check = typeof el.requestFullscreen !== 'undefined' || 
              typeof el.webkitRequestFullscreen !== 'undefined' || 
              typeof el.msRequestFullscreen !== 'undefined' || 
              typeof document.exitFullscreen !== 'undefined' || 
              typeof document.mozCancelFullScreen !== 'undefined' || 
              typeof document.webkitExitFullscreen !== 'undefined';

     
     

      function fullScreen(element:any) {
        if(element.requestFullScreen) {
          element.requestFullScreen();
        } else if(element.webkitRequestFullScreen ) {
          element.webkitRequestFullScreen();
        } else if(element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) { 
        element.msRequestFullscreen()
        }else{
            
        }
      }

      function fullScreenCancel() {
        if(typeof document.exitFullscreen !== 'undefined') {
            document.exitFullscreen()
        } else if(typeof document.mozCancelFullScreen !== 'undefined'){
            document.mozCancelFullScreen()
        } else if (typeof document.webkitExitFullscreen  !== 'undefined' ){
            document.webkitExitFullscreen()
        } else if (typeof document.msExitFullscreen !== 'undefined') { 
            document.msExitFullscreen();
        }else {
            console.log('cencel dont work')
        }
      }
      
      function toggleFullScreen() {
        if (fullScreenState === true) {
            var html = document.documentElement;
            fullScreen(html);
            
            setsetfullScreenState(false)
          
        } else  {
          fullScreenCancel()
          
          setsetfullScreenState(true)
        }
      }

    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);

    function exitHandler() {
        if(styleFS === 'fullScreenClose'){
            setStyleFS('fullScreenOpen')
        }else{
            setStyleFS('fullScreenClose')
        }
        
    }
      

    return (<div>
            <DownloadingPage></DownloadingPage>
            <PopUp></PopUp>
            <WebGLSupport></WebGLSupport>
            <div className='fullscreenContainer'>
                <ButtonPWA></ButtonPWA>
                {check&&<button className={styleFS} onClick={toggleFullScreen} ></button>}  
                <Unity className={styles.playerContainer} unityProvider={unityProvider} />
            </div> 
        </div>
        
    );
}

export default App;