import { useState, useEffect, Fragment } from 'react';
import './DownloadingPage.css'

const DownloadingPage = () => {
    const [isDownloaded, setIsDownloaded] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setIsDownloaded(false);
        }, 7000);
      }, [isDownloaded]);

      let webGLSupport = false;
      const gl = document.createElement('canvas').getContext('webgl2');
      if (!gl) {
        webGLSupport = true;
      } 

     
    return <Fragment>
        {!webGLSupport&&isDownloaded&&<div className='downloading-page-container'>
            <div className="progress-bar-container">    
                    <div className="progress-bar"></div>                       
            </div> 
            <div className='logo-container'></div>
        </div>}
        </Fragment>
}

export default DownloadingPage