import { usePWAInstall } from 'react-use-pwa-install'
import { isMobile } from 'react-device-detect';

import './ButtonPWA.css'
import { Fragment, useEffect, useState } from 'react';

const ButtonPWA = () => {
    const [installPwa, setInstallPwa] = useState(false);

   
    window.addEventListener('appinstalled', (e) => { 
        setInstallPwa(false);
    });
   
   const install = usePWAInstall();
    useEffect(() => {
        if( 'BeforeInstallPromptEvent' in window){
            if (!isMobile){
                setInstallPwa(true);
            }
        }
    }, [])
    
    return<Fragment>
        {installPwa && <button className='addPWA' onClick={install}><span>Install Web App</span> for better experience</button>}
    </Fragment>
}

export default ButtonPWA;