import { Fragment, useState, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import PopUpInstaller from "./PopUpInstaller";

const PopUp = () => {
    const [popUp, setPopup] = useState(false)
      useEffect(() => {
        setTimeout(() => {
            setPopup(true)
        }, 12000);
      }, []);

      let webGLSupport = false;
      const gl = document.createElement('canvas').getContext('webgl2');
      if (!gl) {
        webGLSupport = true;
      } 
      
    return <Fragment>
        {!webGLSupport&&isMobile&&popUp&&<PopUpInstaller></PopUpInstaller>}
    </Fragment>
}

export default PopUp;