import { Fragment } from 'react';
import  './WebGLSupport.css'
const WebGLSupport = () => {
    let webGLSupport = false;
      const gl = document.createElement('canvas').getContext('webgl2');
      if (!gl) {
        webGLSupport = true;
      } 
    return <Fragment>
        {webGLSupport&&<div className='devices-block'>
            <div className='logo-container'></div>
            <div className='devices-container'>
            <div className='title title-center'>Mear on Your Device</div>
        </div>
        <div className='devices center'> 
            <a href='/' id='apple'> </a>
            <a href='/' id='googl'> </a>
            <a href='/' id='x-box'> </a>
            <a href='/' id='microsoft'> </a>
        </div>
</div>}
    </Fragment>
    
}

export default WebGLSupport